export enum EventProducts {
  Avatar = 'Avatar',
  Career = 'Career',
  HomePageWidgetCustomisation = 'Home Page Widgets Customisation',
  AddCompanyDetails = 'Add Company Details',
  AddPaymentMethod = 'Add Payment Method',
  AddPeople = 'Add People',
  BankDetails = 'Bank Details',
  BackgroundChecks = 'Background Checks',
  ComplianceDocuments = 'Compliance Documents',
  CoworkingServicesPage = 'Coworking Services Page',
  ClientInvoices = 'Client Invoices',
  EducationalModal = 'Educational Modal',
  MassOnboarding = 'MassOnboarding',
  GPMassUpdate = 'GP Mass Update',
  Onboarding = 'Onboarding',
  OnboardingTracker = 'Global Onboarding Tracker',
  OffboardingTracker = 'Global Offboarding Tracker',
  Signup = 'Signup',
  EORPayment = 'EOR Payment Items',
  EORQuickAccess = 'EOR Quick Access',
  EORTimeOff = 'EOR Time Off',
  EORBenefits = 'EOR Benefits',
  EORStartSettlement = 'Start settlement',
  EORContractCreation = 'EOR Contract Creation',
  Benefits = 'Benefits',
  EORTerminations = 'EOR Terminations',
  EmployeeResignation = 'Employee Resignation',
  EORTracker = 'EOR Tracker',
  OtherTasks = 'OtherTasks',
  Verification = 'Verification',
  VariableCompensation = 'Variable Compensation',
  Withdrawals = 'Withdrawals',
  WithdrawalMethods = 'Withdrawal Methods',
  EORSubmitAdditionalDetails = 'EOR Submit Additional Details',
  Popup = 'Pop-up',
  VisaApplication = 'Visa Application',
  Tracker = 'Tracker',
  Navigation = 'Navigation',
  CustomDocsBatchUpload = 'Organization Custom Document type - Batch Upload',
  AppsAndIntegrations = ' Apps and Integrations',
  DeelHRFeatures = 'Deel HR Features',
  EORComplianceAndDocuments = 'EOR Compliance and Documents',
  GPReport = 'GP Report',
  EAClientReview = 'EA Client Review',
  GPPayroll = 'GP Payroll',
  PayrollCycleView = 'Payroll cycle view',
  PayrollSupport = 'Payroll Support',
  PAYMENT_FLOW = 'Payment flow',
  ReportPayrollIssues = 'Report payroll issues',
  PayrollDeliverables = 'Payroll Deliverables',
  AnalyticsReports = 'Analytics Reports',
  CustomReports = 'Custom Reports',
  Dashboards = 'Dashboards',
  GrossToNetReport = 'Gross-to-net Report',
  GlobalGrossToNetReport = 'Global gross-to-net Report',
  EORCostCalculator = 'EOR Cost Calculator',
  DirectEmployeePayHistoryReport = 'Employee payment history report',
  HrisIdentityVerification = 'HRIS Identity Verification',
  AddCustomDocumentType = 'Add Custom Document Type',
  Documents = 'Documents',
  HrisCustomDocuments = 'HRIS Custom Documents',
  IdentityVerification = 'Identity Verification',
  OrganizationCustomDocumentType = 'Organization Custom Document type',
  WorkerUploadDocument = 'Worker Upload Document',
  ContractAmendments = 'Contract Amendments',
  ContractView = 'Contract View',
  OrgChart = 'Org Chart',
  DynamicGroups = 'Dynamic Groups',
  EmployeeVerificationLetter = 'Employee Verification Letter',
  GlobalTeamFilter = 'Global Team Filter',
  HelpAndResources = 'Help & Resources',
  Banner = 'Banner',
  RibbonBanner = 'Ribbon Banner',
  MisclasificationBanner = 'Misclasification Banner',
  ContractCancellation = 'Contract Cancellation',
  ContractTermination = 'Contract Termination',
  ContractCreation = 'Contract Creation',
  ICHealthInsurance = 'IC Health Insurance',
  People = 'People',
  ScopeOfWork = 'Scope of Work',
  ClientTriggeredTermination = 'Client Triggered Termination',
  ClientTriggeredEntityMovement = 'Client Triggered Entity Movement',
  ContractAdjustments = 'Contract Adjustments',
  HRISAndGPUpSell = 'Upselling HRIS and GP',
  GlobalPayroll = 'Global Payroll',
  DeelIT = 'Deel IT',
  ServiceHub = 'Service Hub',
  ServiceHubOld = 'Service Hub Old',
  EquityServices = 'Equity Services Page',
  EmployeeList = 'Employee List',
  DataUpdates = 'Data Updates',
  TimeOff = 'Time Off',
  MultifactorAuthentication = 'Multifactor Authentication',
  Home = 'Home',
  Deel2Client = 'Deel2Client',
  PreContractVisa = 'Pre Contract Visa',
  Immigration = 'Immigration',
  ImmigrationEssentials = 'Immigration Essentials',
  ImmigrationPage = 'Immigration Page',
  ImmigrationVisasTab = 'VisasTab',
  VisaAssesments = 'Visa Assesments',
  Quotes = 'Quotes',
  QuotesReceived = 'Quote received',
  VisasTab = 'Visas Tab',
  VIA = 'VIA',
  AppsAndIntegrationsSurvey = 'Apps and Integrations Survey',
  DynamicClientOnboarding = 'Dynamic Client Onboarding',
  DeelCalendarIntegrations = 'Deel Calendar Integrations',
  OwnedEquipmentTrackingContract = 'Owned equipment tracking (contract)',
  OwnedEquipmentTrackingProfile = 'Equipment',
  OwnedEquipmentTrackingTermination = 'Owned Equipment Contract Termination',
  QuickBooks = 'QuickBooks',
  QuickBooksPlugin = 'QuickBooks Plugin',
  QuickBooksICPlugin = 'QuickBooks IC Plugin',
  QuickBooksEORPlugin = 'QuickBooks EOR Plugin',
  Plugin = 'Plugin',
  EORPlugin = 'EOR',
  ICPlugin = 'IC',
  ShieldPlugin = 'Shield',
  SalaryInsights = 'Salary Insights',
  Xero = 'Xero',
  XeroPlugin = 'Xero Plugin',
  XeroICPlugin = 'Xero IC Plugin',
  XeroEORPlugin = 'Xero EOR Plugin',
  Payslips = 'Payslips',
  Cycles = 'Cycles',
  TaxForms = 'Tax Forms',
  CSMBookACall = 'CSM Book a Call',
  CSMBookACallOnboarding = 'CSM Book a Call Onboarding',
  CommunicationAppModal = 'Communication App Modal',
  CommunicationSlackModal = 'Communication Slack Modal',
  CommunicationMSTeamsModal = 'Communication MS Teams Modal',
  BulkAdjustments = 'Bulk Adjustments',
  OffboardingCalculator = 'Offboarding Calculator',
  EquipmentInventory = 'Equipment Inventory',
  TimeTracking = 'Time Tracking',
  TimeAttendanceIngestion = 'Time Attendance Ingestion',
  StartDateAdjustment = 'Start date adjustment',
  RecentPayments = 'Recent Payments',
  PaymentHistory = 'Payment History',
  QAFlags = 'QA Flags',
  G2NVariances = 'G2N Variances',
  G2NReportVariances = 'G2N Report Variances',
  Reports = 'Reports',
  Notifications = 'Notifications',
  TrustedDevices = 'Trusted devices',
  OrganizationSettings = 'Organization settings',
  WorkforcePlanning = 'Workforce planning',
  GlobalPayrollCycleView = 'Global Payroll Cycle View',
  GettingStartedWithHR = 'Getting Started with HR',
  EntitySetup = 'Entity set up',
  EORUnifiedExpenses = 'EOR Unified Expenses',
  UnifiedExpenses = 'Unified Expenses',
  RegistrationAndFiles = 'SFV and Registration',
  CustomDocuments = 'Custom Documents',
  Groups = 'Groups',
  ComplianceAndDocuments = 'Compliance and Documents',
  DeelAdvance = 'Deel Advance',
  EarnedWageAccess = 'Earned Wage Access',
  DeelCard = 'Deel Card',
  CDD1Process = 'CDD1 Process',
  CDD2Process = 'CDD2 Process',
  ClientFramework = 'Client Framework',
  CaseManagement = 'Case Management',
  ImmigrationWallet = 'Immigration Wallet',
  RequestVisaServices = 'Request Visa Services',
  DeelAI = 'Deel AI',
  DeelAIDocumentReview = 'Deel AI Document Review',
  AutoWithdrawals = 'Auto Withdrawals',
  ContractorExternalInvoices = 'Contractor External Invoices',
  ContractorAccountingPage = 'Contractor Accounting Page',
  GPPayment = 'GP Payment',
  Netsuite = 'Netsuite',
  NetsuitePlugin = 'Netsuite Plugin',
  NetsuiteICPlugin = 'Netsuite IC Plugin',
  NetsuiteEORPlugin = 'Netsuite EOR Plugin',
  NetsuiteDeelPlugin = 'Netsuite Deel Plugin',
  EORConsultantAssignment = 'EOR Consultant Assignment',
  MSA = 'MSA',
  SOW = 'SOW',
  EORDocuments = 'EOR Documents',
  Hofy = 'Hofy',
  Learning = 'Learning',
  TopNavigationOnboarding = 'Navigation Onboarding',
  EOREATemplates = 'EOR EA Templates',
  LeasedEquipment = 'Leased Equipment',
  Connectivity = 'Connectivity',
  VendorManagement = 'VMS',
  CustomRoles = 'Custom Roles',
  Referral = 'Referral',
  OrganizationReferral = 'Organization Referral',
  SelfSponsoredVisa = 'Self Sponsored Visa',
  LandingPage = 'Landing Page',
  ServiceProposalLetter = 'Service Proposal Letter',
  Login = 'Login',
  BIOMETRIC_PERMISSION = 'Biometric Permission',
  PIN_CODE = 'Pincode',
  AgnosticContractCreation = 'Agnostic Contract Creation',
  ComplianceHub = 'Compliance Hub',
  Compliance = 'Compliance',
  PersonalCompliance = 'PersonalCompliance',
  VisaForms = 'Visa Forms',
  CSAT = 'CSAT',
  DeelEngage = 'Deel Engage',
  //Role without positions
  RoleWithoutPositionsModal = 'Role Without Positions Modal',
  // PEO
  PEOCSACreation = 'CSA Creation',
  PEOActivation = 'PEO Activation',
  AssignAsGroupAdminTask = 'Assign as Group Admin Task',
  KNOWLEDGE_HUB = 'Knowledge Hub',
  PUSH_NOTIFICATION = 'Push Notification',
  AccountAccess = 'Account Access',

  EORCreator = 'EOR Creator',
  CancelContractTermination = 'Cancel Contract Termination',

  Requests = 'Requests',
  Localization = 'Localization',
  TakeHomePayCalculatorV2 = 'Take Home Pay Calculator V2',
  Activation = 'Activation',
}

export enum EventCategories {
  Click = 'Click',
  Engage = 'Engage',
  Submit = 'Submit',
  View = 'View',
  Form = 'Form',
  FeatureFlag = 'Feature Flag - Visualised',
  AccountingIntegrations = 'Accounting Integrations',
  GrowthFunnelModal = 'Growth Funnel Modal',
  Payroll = 'Payroll',
  Platform = 'Platform',
  CollabIntegrationsConversionFunnels = 'Collab Integrations Conversion Funnels',
  Onboarding = 'Onboarding',
  Popup = 'Pop-up',
  ClientApp = 'Client App',
  Notifications = 'Notifications',
  DeelHR = 'Deel HR',
  Documents = 'Documents',
  Search = 'Search',
  Payments = 'Payments',
  Payouts = 'Payouts',
  Verifications = 'Verifications',
  Mobility = 'Mobility',
  ContractsExtra = 'Contracts Extra',
  Mobile = 'Mobile',
  Analytics = 'Analytics',
  Connectivity = 'Connectivity',
  Transition = 'Transition',
  TimeOff = 'Time Off',
  Language = 'Language',
  ProductRecommendations = 'Product Recommendations',
}

export enum EventActions {
  Cta = 'CTA',
  Filter = 'Filter',
  ClickOnCTA = 'Click on CTA',
  Errors = 'Errors',
  Visualised = 'Visualised',
  VisaApplications = 'Visa Applications',
  EmployeeOnboarding = 'Employee Onboarding',
  EmployeeTermination = 'Employee Termination',
  GoToIntegrations = 'Go To',
  ConnectIntegration = 'Connect',
  ClickBackSlideButton = 'Click back button on popup',
  ClickNextSlideButton = 'Click next button on popup',
  ClickClosePopup = 'Close Popup',
  ClickInvoice = 'Click Invoice',
  ClickBanner = 'Click Banner',
  BookCall = 'Book A Call',
  SuccessfullyBookACall = 'Successfully Book a Call',
  CloseTab = 'Close Tab',
  RedirectToBilling = 'Redirect to the Billing page',
  CopiedText = 'Copied text',
  Hover = 'Hover',
  Info = 'Info',
  ViewDetails = 'View Details',
  ViewDashboard = 'View Dashboard',
  CloseBanner = 'Close Banner',
  Tab = 'Tab',
  Rate = 'Rate',
  Close = 'Close',
  Send = 'Send',
  View = 'View',
  Dismiss = 'Dismiss',
  EmailChange = 'Email Change',
  ResendEmail = 'Resend Email',
  CancelEmailChange = 'Cancel email change',
  ConfirmEmailChange = 'Email change confirm',
  EmailChangeRequested = 'Email change requested',
  StartVerificationWithVeriff = 'Start Verification identity with Veriff',
  Calcom = 'Calcom',
  AddAgentForService = 'Add Agent for Service',
  AddPowerOfAttorney = 'Add Power of Attorney',

  // Integrations - QuickBooks/Xero/Netsuite
  Start = 'Start',
  ClickMapYourAccounts = 'Click Map Your Accounts',
  ClickEditYourAccounts = 'Click Edit Your Accounts',
  ClickInfoIcon = 'Click Info Icon',
  ClickMapSuggestedTab = 'Click Map Suggested Tab',
  ClickMapIndividuallyTab = 'Click Map Individually Tab',
  ClickExitMappingExpenseAccounts = 'Click Exit Mapping Expense Accounts',
  ClickApplyMappingExpenseAccounts = 'Click Apply Mapping Expense Accounts',
  SyncCreateVendorsAutomatically = 'Activates Sync/create vendors automatically',
  ConnectToQuickBooks = 'Connect to QuickBooks',
  EnableEORModules = 'Enable EOR Modules',
  EnableICModules = 'Enable IC Modules',
  EnableShieldModules = 'Enable Shield Modules',
  ClickVendorManagement = 'Click Vendor Management',
  ClickAddVendor = 'Click add Vendor (VM plugin)',
  ClickEditVendor = 'Click edit Vendor (VM plugin)',
  Complete = 'Complete',
  ExitStep1 = 'Exit Step 1',
  ExitStep2 = 'Exit Step 2',
  ExitStep3 = 'Exit Step 3',
  ExitStep4 = 'Exit Step 4',
  ExitStep5 = 'Exit Step 5',
  ContinueToStep2 = 'Continue to Step 2',
  ContinueToStep3 = 'Continue to Step 3',
  ContinueToStep4 = 'Continue to Step 4',
  ContinueToStep5 = 'Continue to Step 5',
  UserSelectsAnEntityDuringStep2 = 'User selects an entity during Step 2',
  GoToVendorList = 'Go to vendor list',
  ManuallyLinkToVendor = 'Manually link to vendor',
  ManuallyUnlinkToVendor = 'Manually unlink to vendor',
  SelectVendorFromDropdown = 'Select a vendor from the dropdown',
  SelectGenerateNewVendor = 'Select Generate a new vendor for your entity',
  ClickApply = 'Click Apply',
  ClickUpdate = 'Click Update',
  ClickCancel = 'Click Cancel',
  ClicksAccountPerInvoiceItem = 'Clicks Account per invoice item',
  ClicksOneAccountForAllInvoices = 'Clicks One Account For All Invoices',
  ExpenseAccountConfigurations = 'Clicks Expense account configurations',
  CustomExpenseCategoryAssignment = 'Clicks Custom expense category assignment',
  ClicksExcludeInvoiceItems = 'Clicks Exclude invoice items by adjustment type',
  ClicksGroupedExpenseAccountMapping = 'Clicks Grouped expense account mapping',
  ClicksUnsyncedInvoices = 'Clicks Unsynced invoices',
  ClicksDeelBillingInvoices = 'Clicks Deel billing invoices',
  ClicksDateFilterDeelBillingInvoices = 'Clicks Date Filter Deel billing invoices',
  ClicksAdvancedSettings = 'Clicks Advanced settings',
  DisableBillInvoiceSync = 'Clicks Disable Bill (invoice) syncing',
  ConfirmDisableBillInvoiceSync = 'Clicks Confirm on Disable Bill (invoice) syncing',
  EnableBillInvoiceSync = 'Clicks Enable Bill (invoice) sync',
  EnablePaymentSync = 'Enable Payment sync',
  DisablePaymentSync = 'Disable Payment sync',
  ConfirmDisablePaymentSync = 'Clicks Confirm on Disable payment syncing',
  EnableDocumentSync = 'Enable Document sync',
  DisableDocumentSync = 'Disable Document sync',
  ConfirmDisableDocumentSync = 'Clicks Confirm on Disable document syncing',
  ToggleAutoInvoiceSync = 'Toggle auto invoice sync',
  ToggleInvoicePdfSync = 'Toggle invoice PDF sync',
  ToggleInvoiceAttachmentsSync = 'Toggle invoice attachments sync',
  TogglesOnSyncInvoicePDF = 'Toggles on Sync invoice PDF',
  ClicksTrackingCategoryMapping = 'Clicks Tracking category mapping',
  ClicksContractorInvoices = 'Clicks contractor invoices',
  ClicksDoneOnAccountMapping = 'Clicks Done on Account Mapping',
  MappingAndSyncModalOpened = 'Mapping and sync modal opened',
  IntegrationConnectedSuccessfullyModalOpened = 'Integration connected successfully modal opened',
  ToggleSyncCreateVendorsAutomatically = 'Toggle Sync/create vendors automatically',

  // PRM Portal
  ApprovePayslips = 'Approve skipped payslip',
  RejectPayslips = 'Reject skipped payslips',
  MarkFlagAsOverruledQAFlags = 'Clicks on Mark as Overruled',
  MarkFlagAsFixedQAFlags = 'Clicks on Mark as Fixed',
  MarkFlagAsOverruledBulkQAFlags = 'Clicks on Mark as Overruled (Bulk)',
  MarkFlagAsFixedBulkQAFlags = 'Clicks on Mark as Fixed (Bulk)',
  CustomReportsCreation = 'Custom Reports Creation',

  // Integrations - Collab Integrations Conversion Funnels
  OpenCommunicationAppModal = 'Open Communication App Modal',
  ClicksSlackOption = 'Clicks on Slack option',
  ClicksMsTeamsOption = 'Clicks on MS Teams option',
  ClicksDontShowThisAgain = 'Check on Don’t show this again checkbox',
  ClicksContinueOnFirstStep = 'Clicks on Continue button on first step',
  ClicksContinueOnSecondStep = 'Clicks on Continue button on second step',
  ClicksCloseOnFirstStep = 'Clicks on Close button on first step',
  ClicksCloseOnSecondStep = 'Clicks on Close button on second step',
  ClicksSendNotificationApprovers = 'Clicks Switch Field Enable Send notification to approvers',
  ClicksSendNotificationChannel = 'Clicks Switch Field Enable Send notification to channel',
  ClicksSendNotificationSignee = 'Clicks Switch Field Enable Send notification to signee',
  ChannelSelection = 'Channel selection',
  ConnectedWithSlack = 'Connected with Slack',
  ConnectedWithMsTeams = 'Connected with MS Teams',
  EnabledNotificationForSlack = 'Click to enable notification for slack',
  EnabledNotificationForMsTeams = 'Click to enable notification for MS Teams',
  ClickOnMsTeamsArticleLink = 'Click on article for how to get Teams Webhook',
  ClickOnSlackArticleLink = 'Click on article for how to send notification to private channel',
  SelectLegalEntity = 'Select Legal Entity',
  SelectAllUnsyncedInvoices = 'Select All Unsynced Invoices',
  SelectAnUnsyncedInvoice = 'Select an Unsynced Invoice',
  SelectSyncInvoicesOncePaymentIsReceived = 'Select Sync invoices once payment is received',
  SelectSyncInvoicesOncePaymentIsSent = 'Select Sync invoices once payment is sent',

  // Integrations - Xero
  ConnectToXero = 'Connect to Xero',

  // Integrations - Netsuite
  ConnectToNetsuite = 'Connect to Netsuite',
  CheckNetsuiteRolePermissions = 'Check Netsuite Role Permissions',
  CheckNetsuiteAccountingPeriods = 'Check Accounting Periods',
  CheckNetsuiteMultiCurrency = 'Check Multi Currency',
  ClicksOnTheNetsuiteIconForAccountId = 'Clicks on the icon for Account ID',
  ClicksOnTheNetsuiteIconForConsumerKey = 'Clicks on the icon for Consumer Key',
  ClicksOnTheNetsuiteIconForConsumerSecret = 'Clicks on the icon for Consumer Secret',
  ClicksOnTheNetsuiteIconForTokenKey = 'Clicks on the icon for Token key',
  ClicksOnTheNetsuiteIconForTokenSecret = 'Clicks on the icon for Token Secret',
  SelectNetsuiteItems = 'Select Netsuite Items',
  SelectNetsuiteExpenses = 'Select Netsuite Expenses',
  SelectNetsuiteSubsidiary = 'Select Subsidiary',

  // Growth Funnel Modal
  GAMOpen = 'Growth Accounting Modal Opened',
  GAMClose = 'Growth Accounting Modal Close',
  GAMButtonPressed = 'Growth Accounting Modal Button pressed',

  // Tax Forms
  VisualizeTaxForm = 'Visualize Tax Form',
  ClickCreateTaxForm = 'Click Create Tax Form',
  ClickConfirmDetailsOnTaxForm = 'Click Confirm Details on Tax Form',
  ClickContinueOnTaxForm = 'Click Continue on Tax Form',
  ClickOnLinkOnTaxForm = 'Click on Link on Tax Form',
  ClickOnDownloadLinkOnTaxForm = 'Click on Download Link on Tax Form',
  ClickInfoOnTaxForm = 'Click Info on Tax Form',
  ClickPreviewTaxForm = 'Click Preview Tax Form',
  ClickSignTaxForm = 'Click Sign Tax Form',
  CreateTaxForm = 'Create Tax Form',
  UpdateTaxForm = 'Update Tax Form',
  ClickDownloadTaxForm = 'Click Download Tax Form',
  ClickEditTaxForm = 'Click Edit Tax Form',
  SubmitDataToIRS = 'Submit data to IRS',
  OpenTaxPage = 'Open tax page',
  ViewTotalPay = 'View total pay',
  ViewStatusDetails = 'View status details',
  OpenTaxFormDetails = 'Open Tax Form Details',
  OpenReviewAndFile1099 = 'Open Review and file 1099',
  OpenChangePayOutsideDeelPopup = 'Open change pay outside Deel popup',
  ChangePayOutsideDeel = 'Change pay outside Deel',

  // Trusted Devices
  SelectSecurity = 'Select Security',
  ManageTrustedDevices = 'Manage Trusted Devices',
  RemoveAuthenticator = 'Remove Authenticator',
  AuthenticatorApp = 'Authenticator app',
  RemoveAllDevices = 'Remove All Devices',
  NoTrustedDevicesYet = 'No Trusted Devices Yet',
  LoginVerificationRequired = 'Login Verification Required',
  RemoveSingleDevice = 'Remove Single Device',
  RenewalPeriod = 'Renewal Period',
  RemoveDeviceConfirmation = 'Remove Device Confirmation',
  DeviceRemoved = 'Device removed',
  ConfirmDeviceRemoval = 'Confirm Device Removal',

  // 2FA/MFA Authentication
  MFAGetSetup = '2FA Get Set up',
  MFASkip = '2FA Complete Sign up (Skip 2FA)',
  MFAEnabledConfirmation = '2FA Enabled Confirmation',
  MFAEnable = '2FA Enable',
  MFAModalSkip = '2FA Modal Skip',
  MFAModalGetSetup = '2FA Modal Continue',

  // Managers
  AccessManagementInviteManager = 'Access Management Invite Admin',
  AccessManagementSelectRole = 'Access Management Select Role',
  AccessManagementSaveRoleChanges = 'Access Management Save Role Changes',
  AccessManagementRemoveAccess = 'Access Management Remove Access',
  AccessManagementAssignRoleSteps = 'Access Management Assign Role Steps',
  AccessManagementInviteAdminExistingWorkers = 'Invite Admin Existing Worker',
  AccessManagementInviteAdminNewWorkers = 'Invite Admin New Worker',
  AccessManagementAssignRoleExistingWorker = 'Assign Role Existing Worker',
  AccessManagementAssignRoleNewWorker = 'Assign Role New Worker',

  // Groups
  ViewGroups = 'View Groups',
  CreateGroup = 'Create Group',
  ManualConfiguration = 'Manual Configuration',
  GroupMembers = 'Group Members',
  ManagersSelected = 'Managers Selected',
  AssignedManagerRoles = 'Assigned Manager Roles',
  SelectManagerRoles = 'Select Manager Roles',
  AddGroup = 'Add Group',
  GroupOptions = 'Group options',
  DeleteGroup = 'Delete Group',
  EditGroupName = 'Edit Group Name',
  ContractSettings = 'Contract Settings',
  GeneralLedgerAccount = 'General Ledger Account',
  InvoiceSettings = 'Invoice Settings',
  RequireApproval = 'Require Approval',
  ApproversRequired = 'Approvers Required',
  ReplicateGroup = 'Replicate Group',
  ExitGroup = 'Exit Group',
  ToggleOrganizationAdminInclusion = 'Toggle Organization Admin Inclusion',

  // Custom Roles
  CustomRoleTemplate = 'Custom Role Template',
  CustomRoleEdit = 'Custom Role Edit',
  CustomRoleDelete = 'Custom Role Delete',
  CreateCustomRole = 'Create Custom Role',
  CustomRolePermissions = 'Custom Role Permissions',
  CompareRoles = 'Compare Roles',
  CustomRoleSteps = 'Custom Role Steps',
  SimulateRole = 'Simulate Role',

  // Deel Advance
  CTAPresented = 'CTA Presented',
  TOSAccepted = 'TOS Accepted',
  InputAmountChanged = 'Input Amount Changed',
  RequestSubmitted = 'Request Submitted',
  SubmissionFeedbackPopupClosed = 'Submission Feedback Popup Closed',
  OpenAdvanceModal = 'Open Advance Modal',
  EducationalPopupButtonClicked = 'Educational Popup Button Clicked',
  EducationalPopupPresented = 'Educational Popup Presented',

  // CDD1 Process
  CompleteCompanyProfileStepCDD1 = 'Complete Company Profile Step',
  CompleteEntityDetailsStepCDD1 = 'Complete Entity Details Step',
  CompleteEntityAddressStepCDD1 = 'Complete Entity Address Step',
  CompletePointOfContactStepCDD1 = 'Complete Point of Contact Step',
  CompleteReviewInformationStepCDD1 = 'Complete Review Information Step',

  // CDD2 Process
  StartCDD2Process = 'Start Process',
  CompleteCompanyDocumentsStepCDD2 = 'Complete Company Documents Step',
  CompleteOwnershipInformationStepCDD2 = 'Complete Ownership Information Step',
  CompleteReviewInformationStepCDD2 = 'Complete Review Information Step',
  ErrorsCDD2 = 'Errors',
  InteractedWithArticlesOfIncorporationCDD2 = 'Company Documents Interacted with Articles of Incorporation',
  InteractedWithVATDocumentCDD2 = 'Company Documents - Interacted with TIN/EIN Document',
  InteractedWithProofOfCompanyBankStatementCDD2 = 'Company Documents - Interacted with Proof of Company Bank Statement',
  InteractedWithPersonOfSignificantControlCDD2 = 'Ownership Information - Interacted with Person of Significant Control',
  InteractedWithTypeOfPersonOfSignificantControlCDD2 = 'Ownership Information - Interacted with Type',
  InteractedWithThisIsMeCDD2 = 'Ownership Information - Interacted with This is Me Checkbox',
  InteractedWithRoleCDD2 = 'Ownership Information - Interacted with Role',
  InteractedWithCountryOfResidenceCDD2 = 'Ownership Information - Interacted with Country of Residence',
  InteractedWithDocumentTypeCDD2 = 'Ownership Information - Interacted with Identification Document Type',
  InteractedWithPercentageCDD2 = 'Ownership Information - Interacted with Percentage',
  InteractedWithTaxIDCDD2 = 'Ownership Information - Interacted with Tax ID',
  InteractedWithFirstNameCDD2 = 'Ownership Information - Interacted with First Name',
  InteractedWithLastNameCDD2 = 'Ownership Information - Interacted with Last Name',
  InteractedWithEntityNameCDD2 = 'Ownership Information - Interacted with Entity Name',
  InteractedWithFrontImageUploadCDD2 = 'Ownership Information - Interacted with Front Image Upload',
  InteractedWithBackImageUploadCDD2 = 'Ownership Information - Interacted with Back Image Upload',
  InteractedWithCertifyingCorrectnessCDD2 = 'Ownership Information - Interacted with Certifying Correctness',

  // Background Checks
  ClickOnAddBackgroundCheckPackageCTA = 'Click on Add Background Check Package CTA',
  ClickOnAddIndividualBackgroundCheckCTA = 'Click on Add Individual Background Check CTA',
  ClickOnContractCreationBackgroundCheckCTA = 'Click on Contract Creation Background Check CTA',
  ContinueNewBackgroundCheckRequest = 'Continue New Background Check Request',
  ClickOnOfferBackgroundCheckCTA = 'Click on Offer Background Check CTA',
  ClickOnViewResults = 'Click on View Results ',
  RequestBackgroundCheck = 'Request Background Check',
  ClickOnRequestBackgroundCheck = 'Click on Request Background Check',
  ClickOnGetStarted = 'Click on Get Started',
  ProceedToCreateACertnAccount = 'Proceed to Create a Certn Account',
  CreateCertnAccount = 'Create Certn Account',
  ConfirmCertnAccountCreation = 'Confirm Certn Account Creation',

  // Group Filter
  ChooseAGroup = 'Choose a Group',
  AddNewGroup = 'Add a New Group',
  ClickOnGlobalGroupFilter = 'Click on Global Group Filter',
  GroupSwitcherSettings = 'Group Switcher Settings',

  // Engage Learning
  CreateJourney = 'Create Journey',
  CancelJourneyCreation = 'Cancel Journey Creation',
  CancelJourneyUpdate = 'Cancel Journey Update',
  ClickOnViewJourney = 'Click on View Journey',
  ClickOnUseTemplate = 'Click on Use Template',
  ClickOnViewDetailsTemplate = 'Click on View Details Template',
  CreatedJourney = 'Journey created',
  UpdatedJourney = 'Journey updated',
  ClickOnUpdateJourney = 'Click on Update Journey',
  ClickOnSubmitJourney = 'Click on Submit Journey',
  ClickOpenJourneyEditor = 'Click Open Journey Editor',
  JourneyDelete = 'Journey Delete',
  JourneyCancelDelete = 'Journey Cancel Delete',
  OpenJourneySettings = 'Open Journey Settings',
  JourneyDuplicate = 'Journey Duplicate',
  ClickOnJourneyDelete = 'Click on Journey Delete',
  OpenJourneyActionsMenu = 'Open Journey Actions Menu',
  ClickOnJourneyPreview = 'Click on Journey Preview',
  ClickOnTemplatePreview = 'Click on Template Preview',
  JourneyPublish = 'Journey Publish',
  JourneyStepEditorCommandClick = 'Journey Step Editor Command Click',
  JourneyDeleteSteps = 'Delete Steps',
  BuildContent = 'Build content',
  BuildContentEngagement = 'Build content Engagement',
  EmbedResource = 'Embed resource',
  PreviewMode = 'Preview mode',
  CreateAutomation = 'Create Automation',
  AutomationSelectGroups = 'Automation select groups',
  AutomationTrigger = 'Automation Trigger',
  AutomationAdvancedSettings = 'Automation advanced settings',
  AutomationActivate = 'Automation Activate',
  AutomationDeactivate = 'Automation Deactivate',
  AssignPeople = 'Assign people',
  MoreOptions = 'More Options',
  AssignmentsViewDetails = 'Assignments View Details',
  DeleteJourneyConfirmation = 'Delete Journey confirmation',
  EditJourneyDetails = 'Edit Journey details',
  JourneyEnroll = 'Journey Enroll',
  JourneyCancelEnroll = 'Journey Cancel Enroll',
  JourneyUnenroll = 'Journey Unenroll',
  JourneyCancelUnenroll = 'Journey Cancel Unenroll',
  JourneyEmptyStateQuickActionClick = 'Journey Empty State Quick Action',

  // External Signing
  OpenExternalSigning = 'Client Clicks Invite External Button',
  InviteExternalSigner = 'Client Invited External',
  ExternalSignerAuth = 'External Authenticated',
  ExternalSignerSigned = 'External Signed/Accepted',

  Invited = 'Invited',
  LearnMore = 'Learn more',

  // Service Proposal Letter
  ViewServiceProposalLetter = 'Services Proposal Letter button visualised',
  DownloadServiceProposalLetter = 'Services Proposal Letter button clicked',

  // Native Mobile
  ForgotPassword = 'Forgot Password',
  SSOFlow = 'SSO Flow',
  FaceIDEnable = 'FaceID Enable',
  FaceIDVerify = 'FaceID Verify',
  FaceIDError = 'FaceID Error',
  FaceIDPermissionOn = 'FaceID Permission On',
  FaceIDOff = 'FaceID Off',
  DisableEnterPassword = 'Disable Enter Password',
  Setup = 'Set Up',
  ForgotPinCode = 'Forgot Pincode',
  IncorrectPinCode = 'Incorrect Pincode',
  PushNotificationClick = 'Push Notification Click',

  // Profile Suggestions / Fuzzy Matching
  UserCreatedNewWorkerAndIgnoredSuggestions = 'User Created a new worker despite having suggestions to select',
  UserSelectedASuggestionProvided = 'User selected one of the suggestions provided',

  // Compliance Hub
  ClickReviewOnComplianceTasks = 'Click "Review" on Compliance Tasks',
  ClickDismissOnComplianceTasks = 'Click "Dismiss" on Compliance Tasks',
  SearchInComplianceHub = 'Search in compliance Hub',
  ClickOnFiltersOnUpdates = 'Click on Filters on Updates',
  SelectFiltersOnUpdates = 'Select Filters on Updates',
  CloseTheArticle = 'Close the Article',
  ClickReviewButtonIndividualContractor = 'Click "Review" button individual contractor',
  SearchResultsQuantity = 'Search results quantity',
  ClickConvertToEmployee = 'Click "Convert to Employee"',
  ClickLearnMoreAlertOfMisclassification = 'Click "Learn More" alert of Misclassification',
  ViewsEquityRiskTask = 'Views equity risk task',
  ClickOnReviewForEquityTask = 'Click on review for equity risk',
  ViewEquityRiskModal = 'View equity risk modal',
  ClickOnSecondaryCtaEquityRiskModal = 'Clicks on secondary CTA equity risk modal',
  ClickOnPrimaryCtaEquityRiskModal = 'Clicks on primary CTA equity risk modal',
  ClickOnDismissEquityRiskModal = 'Clicks on dismiss equity risk modal',
  ClickWorkerAssessmentButton = 'Click "Worker Assessment" button',
  ClickMassAssessmentButton = 'Click "Mass Assessment" button',
  ClickHiringWorkerAssessmentButton = 'Click "Hiring Worker Assessment" button',
  CloseAssessment = 'Close Assessment',
  StartAssessment = 'Start Assessment',
  ClickRetakeAssessment = 'Click "Retake Assessment"',
  NextStep = 'Next Step',
  AnswersSelectedOnEachQuestion = 'Answers selected on each question',
  FinishAssessment = 'Finish Assessment',
  SelectCountry = 'Select Country',
  TimePerStep = 'Time per step',
  ClickEmployeeAssessmentButton = 'Click Employee "Take Assessment" button',

  // Referrals
  InviteSocial = 'Invite social',
  InviteEmail = 'Invite email',
  CopyLink = 'Copy link',
  ClickReferralLink = 'Click referral link',
  PreviewEmailClick = 'Preview email click',
  SearchInvitations = 'Search invitations',
  InvitationsSearchResult = 'Invitations search result',
  OpenClaimModal = 'Open claim modal',
  ReferralCountryClick = 'Referral country click',
  SelectGiftCountry = 'Select gift country',

  // EOR Contract Creation
  FixJobScopeDetails = 'Fix erros in job scope details',
  UpdateJobScopeDetails = 'Update job scope details',

  FieldUpdate = 'Field Update',
  FinishCancellation = 'Finish Cancellation',
  LanguageSwitch = 'Language Switch',

  // Withdrawals
  UseFullBalance = 'Use Full Balance',
  UseMaxAmount = 'Use Max Amount',
  UseCustomAmount = 'Use Custom Amount',
  ConfirmWithdraw = 'Confirm Withdrawal',
  Withdraw = 'Withdraw',
}

export enum EventPageTitle {
  HOME = 'Home',
  CONTRACT_REVIEW_AND_SIGN = 'Review & Sign',
  TAX_FORMS = 'Tax Forms',
  TAX_FORM_DETAILS = 'Tax Form Details',
  CREATE_TAX_FORM = 'US tax form creation',
  EDIT_TAX_FORM = 'US tax form edit',
  FILE_FORM_1099 = 'File forms 1099',
}
